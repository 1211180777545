import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import star from "../assets/star.png";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import arrow from "../assets/arrow.svg";
import testimonial from "../assets/review-main.png";
import user1 from "../assets/user-1.jpeg";
import user2 from "../assets/user-2.jpeg";
import user3 from "../assets/user-3.jpeg";
import user4 from "../assets/user-4.jpeg";
import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/gas.webp";
import groceryImage from "../assets/food.webp";
import rentImage from "../assets/rent.webp";
import shoppingImage from "../assets/bills.webp";
import trustseal from "../assets/trust-seal.png";

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          <div className="trusted-seals">
            <div className="container">
              <div className="row">
                <div className="trusted-seal-headline">
                  <h2>Trusted Partners, Secured Promise</h2>
                </div>
                <div className="trusted-seal-wrapper">
                  <img src={trustseal} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="claims">
            <div className="container">
              <div className="row">
                <div className="claim-headline-wrapper">
                  <div className="claim-headline-left">
                    <h2>Transforming Lives: The Impact of the Subsidy Program</h2>
                  </div>
                  <div className="claim-headline-right">
                    <p>Discover the Subsidy Program, a crucial initiative that helps low-wage Americans save on health premiums. Learn how these savings make it easier to afford essential expenses like groceries, rent, gas, and bills. This overview highlights the significant impact and the ongoing progress of the program.</p>
                  </div>
                </div>
                <div className="item-container">
                  <div className="claim-item">
                    <div className="bignumber">
                      $43 Million
                    </div>
                    <div className="smalltext">
                      <p>The ripple effect of financial empowerment is evident, with millions allocated to uplift households across the nation.</p>
                    </div>
                  </div>
                  <div className="claim-item">
                    <div className="bignumber">
                      250,000+
                    </div>
                    <div className="smalltext">
                      <p>Many lives have been touched, with more families joining every day to avail the comprehensive benefits.</p>
                    </div>
                  </div>
                  <div className="claim-item">
                    <div className="bignumber">
                      1 in 3
                    </div>
                    <div className="smalltext">
                      <p>Reflecting the widespread need, a third of the population qualifies for this life-enhancing program, ensuring no one is left behind.</p>
                    </div>
                  </div>
                  <div className="claim-item">
                    <div className="bignumber">
                      482 Seats
                    </div>
                    <div className="smalltext">
                      <p>With limited spots remaining, urgency meets opportunity for those aiming to harness the program's full potential.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Discover the Difference Today!</h1>
                  <p>Join a community of satisfied customers, evidenced by our outstanding Trustpilot rating. Experience our unparalleled service in just a click. See why we're the preferred choice!</p>
                </div>
                <div className="testimonial-content-holder">
                  <img src={testimonial} alt="" />
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user1}></img>
                          <h3>Floyd Miles</h3>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                        <img src={star} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user2}></img>
                          <h3>Michael Anderson</h3>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          Just in time when i need it the most! I can't thank US Support enough for their prompt assistance. Their team's commitment to me is truly commendable
                        </p>
                        <img src={star} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user3}></img>
                          <h3>Olivia Martinez</h3>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          I was in a financial bind, and US Support came to the rescue. Their team's dedication and service to people are top-notch, and I couldn't be happier with their help.
                        </p>
                        <img src={star} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is a non-government website that is not affiliated with or endorsed by the U.S. government or Healthcare.gov. The purpose of this site is the solicitation of insurance. Contact may be made by an insurance agent/producer or insurance company. Not all products are available in all states. We represent various insurance carriers, dental plans, vision plans, Hospital Gap coverage plans and Prescription Drug Plans (PDP). We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area. Not all plans offer all of these benefits. Benefits may vary by carrier and location. Limitations and exclusions may apply. By using this site, you acknowledge that you have read and agree to our Privacy Policy and Terms of Service
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>© Copyright {window.domain_settings.websiteTitle} 2024</p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
